<template>
  <div class="app-list-container">
    <div class="header">
      <el-col :span="10" class="search-option">
        <span style="margin: 0 20px;"
          >总邀请人数：{{ rankingData.totalNum }}</span
        >
        <span style="margin: 0 20px;"
          >内部邀请人数：{{ rankingData.manualNum }}</span
        >
      </el-col>
      <el-col class="create-option">
        <!--<el-button type="info" @click="handleSubmit('runRanking')">生成排行榜</el-button>-->
        <el-button type="success" @click="handleSubmit('reloadActivity')"
          >刷新活动信息</el-button
        >
        <el-button type="danger" @click="handleSubmit('settleInviteAward')"
          >结算邀请奖励</el-button
        >
        <el-button type="warning" @click="handleSubmit('settleRankingAward')"
          >结算排行榜</el-button
        >
        <el-button
          type="primary"
          @click="
            postForm = {};
            showCreateDialog = true;
          "
          >添加内部账户</el-button
        >
      </el-col>
    </div>

    <el-table :data="rankingData.rankingList" border stripe>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="400" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="+scope.row.is_manual == 1"
            size="mini"
            type="primary"
            @click="handleEdit(scope.row)"
          >
            增加人数
          </el-button>
          <el-button
            v-if="+scope.row.is_manual == 0"
            size="mini"
            type="success"
            @click="handleDetail(scope.row)"
          >
            明细
          </el-button>
          <el-button
            v-if="
              +scope.row.status == -1 ||
                +scope.row.status == 0 ||
                +scope.row.status == 1
            "
            size="mini"
            type="primary"
            @click="handleAudit(scope.row.user_id, 1)"
          >
            通过
          </el-button>
          <el-button
            v-if="
              +scope.row.status == -1 ||
                +scope.row.status == 0 ||
                +scope.row.status == 1
            "
            size="mini"
            type="danger"
            @click="handleAudit(scope.row.user_id, -1)"
          >
            拒绝
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="'增加'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="showCreateDialog = false"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="账户">
          <el-input v-model="postForm.username"></el-input>
        </el-form-item>
        <el-form-item label="邀请数量">
          <el-input v-model="postForm.invite_num"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="'提交'"
      :visible.sync="showSubmitDialog"
      width="30%"
      @close="showSubmitDialog = false"
    >
      <el-form ref="form" :model="postForm2" label-width="80px">
        <el-form-item label="密码">
          <el-input v-model="postForm2.password"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showSubmitDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit2">确 定</el-button>
      </span>
    </el-dialog>

    <el-drawer :visible.sync="showDrawer" direction="rtl" size="70%">
      <el-table :data="inviteList" class="invite-list-table">
        <el-table-column
          property="user_id"
          label="用户id"
          width="100"
        ></el-table-column>
        <el-table-column
          property="mobile"
          label="手机"
          width="120"
        ></el-table-column>
        <el-table-column
          property="email"
          label="邮箱"
          width="200"
        ></el-table-column>
        <el-table-column
          property="created_at"
          label="创建时间"
          width="210"
        ></el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postInvite, postAudit } from "@/api/ranking";
import { getWithdrawRecordInfo } from "@/api/withdraw";
import axios from "axios";
import config from "@/configs/index";

const activityApi = config.url + "/admin_wallet/api/admin/activity";

export default {
  data() {
    return {
      columns: [
        { id: "rank", label: "排名" },
        { id: "username", label: "用户名" },
        {
          id: "is_manual",
          label: "用户类型",
          formatter: (row) => {
            switch (+row.is_manual) {
              case 1:
                return <el-tag>内部账号</el-tag>;
              case 0:
                return <el-tag type="success">真实账号</el-tag>;
              default:
                return row.is_manual;
            }
          },
        },
        { id: "invite_num", label: "邀请人数" },
        {
          id: "reward",
          label: "预计奖励",
          formatter: this.rewardFormatter,
        },
        {
          id: "status",
          label: "状态",
          formatter: (row) => {
            switch (+row.status) {
              case 9:
                return <el-tag>已结算</el-tag>;
              case 1:
                return <el-tag type="success">已审核</el-tag>;
              case 0:
                return <el-tag type="warning">待审核</el-tag>;
              case -1:
                return <el-tag type="danger">审核拒绝</el-tag>;
              case -2:
                return <el-tag type="error">未生成数据</el-tag>;
              default:
                return "";
            }
          },
        },
      ],
      reward: 10,
      postForm: {},
      postForm2: {},
      inviteList: [],
      isEdit: false,
      showDrawer: false,
      showCreateDialog: false,
      showSubmitDialog: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["rankingData"]),
  },
  methods: {
    fetchData() {
      this.$store.dispatch("ranking/getRanking");
    },
    rewardFormatter(row) {
      return this.rankingData.reward * row.invite_num;
    },
    handleEdit(data) {
      this.showCreateDialog = true;
      this.isEdit = true;
      this.postForm = {
        username: data.username,
        invite_num: this.invite_num,
      };
    },
    async handleDetail(row) {
      const loadingInstance = this.$loading();
      console.log(loadingInstance);
      try {
        const { data } = await getWithdrawRecordInfo({
          user_id: row.user_id,
          withdraw_id: 0,
        });
        this.showDrawer = true;
        this.amountInvalid = data.amountInvalid;
        this.inviteList = data.data;
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$message({ type: "error", message: "查询失败!" });
      }
    },
    handleAudit(user_id, status) {
      postAudit({ user_id, status }).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
          this.handleSuccess();
        } else {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.handleSuccess();
        }
      });
    },
    onSubmit() {
      postInvite(this.postForm).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
          this.handleSuccess();
        } else {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.handleSuccess();
        }
      });
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.showSubmitDialog = false;
      this.postForm = {};
      this.postForm2 = {};
      this.isEdit = false;
      this.fetchData();
    },
    handleSubmit(router) {
      this.showSubmitDialog = true;
      this.isEdit = true;
      this.postForm2 = {
        router: router,
        password: this.password,
      };
    },
    async onSubmit2() {
      let { router, password } = this.postForm2;
      if (password !== "123456") {
        this.$message({
          message: "密码错误",
          duration: 3000,
          type: "error",
        });
        return;
      }
      try {
        const res = await axios.get(`${activityApi}/${router}`);
        if (res && res.data && +res.data.code === 0) {
          this.$message({ message: "成功", duration: 3000, type: "success" });
          this.handleSuccess();
        } else {
          this.$message({
            message: res.data.msg,
            duration: 3000,
            type: "fail",
          });
          this.handleSuccess();
        }
      } catch (error) {
        console.log(error);
        this.handleSuccess();
        this.$message({ message: "操作失败", duration: 3000, type: "fail" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invite-list-table {
  max-height: 100vh;
  overflow-y: scroll;
}
.app-list-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
